import { gsap } from "../gsap/all.js";
import { ScrollTrigger } from "../gsap/ScrollTrigger.js";
import A11yDialog from "a11y-dialog";
gsap.registerPlugin(ScrollTrigger);
gsap.config({nullTargetWarn:false});
export default {

  init() {
    $('.if_filter_list').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      generateSlickPages('.integrations_filter .pagination-align', event, slick, currentSlide, nextSlide);
    });

    $('.pf-filter-list').on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      generateSlickPages('.post-feed .pagination-align', event, slick, currentSlide, nextSlide);
    });

    var $nav = $('header .nav-wrap.sticky');
    var scrollTriggerHeight = 25;
    var lastScrollTop = 0;

    $(window).scroll(function() {
      var scrollTop = $(this).scrollTop();
      var isScrollingUp = scrollTop < lastScrollTop;

      if (scrollTop > scrollTriggerHeight) {
        $nav.addClass('sticky-hidden');
      } else {
        $nav.removeClass('sticky-hidden');
      }

      if (isScrollingUp) {
        $nav.addClass('scrollingUp').removeClass('sticky-hidden');
      } else {
        $nav.removeClass('scrollingUp');
      }

      lastScrollTop = scrollTop;
    });

    function scrollDirection(last, delta, st, scroll) {
      if (Math.abs(last - st) <= delta) {
        return;
      }

      if (st > lastScrollTop) {
        scroll = 'down';
      } else {
        scroll = 'up';
      }

      lastScrollTop = st;
      return scroll;
    }

    // Integration Filter Systems

    if ($('.if_filter__button').length > 0) {
      $(document).on('click', '.if_filter__button', function(e){
        e.preventDefault();
        $(this).toggleClass('active');
      });

      $(document).on('click', '.if_filter__option', function(e){
        e.preventDefault();
        if (!$(this).hasClass('active')) {
          var filter = $(this).attr('data-filter');
          $('.if_filter__select .active').removeClass('active');
          $(this).addClass('active');
        } else {
          $('.if_filter__select .active').removeClass('active');
        }

        $('.if_filter__button').toggleClass('active');
        filterIntegrations();
      });
    }

    var ifTimer;
    var doneIfTimer = 500;

    //on keyup, start the countdown
    $(document).on('keyup', 'input[name="if-filter"]', function () {
      clearTimeout(ifTimer);
      ifTimer = setTimeout(filterIntegrations, doneIfTimer);
    });

    //on keydown, clear the countdown 
    $(document).on('keydown', 'input[name="if-filter"]', function () {
      clearTimeout(ifTimer);
    });

    function filterIntegrations() {
      var text = $('input[name="if-filter"]').val().toLowerCase(),
      cat  = ($('.if_filter__select .active').length == 1) ? $('.if_filter__select .active').attr('data-filter').toLowerCase() : false,
      full = $('.if_filter__hidden').html();

      console.log(text);

      $('.if_filter_list').slick('unslick');

      $('.if_filter_list').html(full);

      if (text && text != '') {
        $('.if_filter_list > div').each(function(){
          var filter = $(this).attr('data-filter').toLowerCase();
          if (filter.indexOf(text) == -1) {
            $(this).remove();
          }
        });
      }

      if (cat && cat != '') {
        $('.if_filter_list > div').each(function(){
          var cf = $(this).attr('data-cat').toLowerCase();
          if (cat != cf) {
            $(this).remove();
          }
        });
      }

      var count = $('.if_filter_list > div').length, 
      max = Math.ceil(count / 6);

      $('.integrations_filter .curr-page').html('1');
      $('.integrations_filter .max-page').html(max);

      $('.if_filter_list').slick({
        dots: false,
        arrows: true,
        slidesPerRow: 2,
        fade: true,
        rows: 3,
        infinite: false,
        prevArrow: $('.prev-if'),
        nextArrow: $('.next-if'),
      });

    }

    function stopFloating(){
      $('.floating_card.floating').removeClass('floating').removeClass('floating_up').removeClass('floating_down');
    }

    if ($('.floating_card').length > 0) {
      // Scroll direction for floating elements
      var lastScrollTop = 0,
      scroll = 'stopped',
      hasAnimation = false;

      $(window).scroll(function(event) {
        clearTimeout($.data(this, 'scrollTimer'));
        $.data(this, 'scrollTimer', setTimeout(function() {
          scroll = 'stopped';
          hasAnimation = false;
          stopFloating();
        }, 50));

        var st = $(this).scrollTop();
        scroll = scrollDirection(lastScrollTop, 5, st, scroll);

        if (!hasAnimation && scroll && scroll !== null && scroll !== undefined) {
          hasAnimation = true;
          stopFloating();
          var dir = (scroll == 'up' ? 'down' : 'up');
          $('.floating_card:not(.floating)').addClass('floating').addClass('floating_'+dir);
        }
      });
    }

    // Paginated Post Feed

    $(document).on('click', '.load-more', function(e) {
      e.preventDefault();

      var id = $(this).attr('data-section'),
      postType = $(this).attr('data-post-type'),
      contains = $(this).attr('data-contains'),
      tag      = $(this).attr('data-tag'),
      current  = parseInt($(this).attr('data-current'), 10),
      max      = $(this).attr('data-max');

      console.log(id);

      $('.load-more').attr('data-contains', contains);
      $('.load-more').attr('data-tag', tag);
      $('#'+id+' .load-more').hide();
      $('#'+id+' .pagination-count svg').show();
      $('#'+id+' .pagination-count .no-posts').hide();

      $.ajax({
        url: ajaxurl+'?action=loadMorePosts',
        type: 'post',
        data: {section: id, postType: postType, contains: contains, tag: tag, current: current},
        success: function(data) {
          var dataArray = JSON.parse(data),
          current = parseInt(dataArray['current'], 10),
          id = dataArray['id'];
          console.log(id);

          $('#'+id+' .current-page').html(current);
          $('.load-more').attr('data-current', current);
          $('#'+id+' .pf-filter-container').append(dataArray['html']);
          setTimeout(function(){
            AOS.refresh();
          }, 200);
          setTimeout(function(){
            $('#'+id+' .pf-post-card:not(.aos-animate)').addClass('aos-animate');
          }, 400);

          $('#'+id+' .pagination-count svg').hide();
          if (current == max) {
            $('#'+id+' .pagination-count .no-posts').show();
          } else {
            $('#'+id+' .load-more').show();
          }
        },
        error: function(jqXHR, textStatus, errorThrown){
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
    });

     // Paginated Resources

     $(document).on('click', '.load-more-res', function(e) {
      e.preventDefault();

      var id = $(this).attr('data-section'),
      postType = $(this).attr('data-post-type'),
      contains = $(this).attr('data-contains'),
      tag      = $(this).attr('data-tag'),
      current  = parseInt($(this).attr('data-current'), 10),
      max      = $(this).attr('data-max');

      console.log(id);

      $('.load-more-res').attr('data-contains', contains);
      $('.load-more-res').attr('data-tag', tag);
      $('#'+id+' .load-more-res').hide();
      $('#'+id+' .pagination-count svg').show();
      $('#'+id+' .pagination-count .no-posts').hide();

      $.ajax({
        url: ajaxurl+'?action=loadMorePostsRes',
        type: 'post',
        data: {section: id, postType: postType, contains: contains, tag: tag, current: current},
        success: function(data) {
          var dataArray = JSON.parse(data),
          current = parseInt(dataArray['current'], 10),
          id = dataArray['id'];
          console.log(id);

          $('#'+id+' .current-page').html(current);
          $('.load-more-res').attr('data-current', current);
          $('#'+id+' .pf-filter-container').append(dataArray['html']);
          setTimeout(function(){
            AOS.refresh();
          }, 200);
          setTimeout(function(){
            $('#'+id+' .pf-post-card:not(.aos-animate)').addClass('aos-animate');
          }, 400);

          $('#'+id+' .pagination-count svg').hide();
          if (current == max) {
            $('#'+id+' .pagination-count .no-posts').show();
          } else {
            $('#'+id+' .load-more-res').show();
          }
        },
        error: function(jqXHR, textStatus, errorThrown){
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
    });

    // Nav Animation

    let pstl = gsap.timeline({
      scrollTrigger: {
        trigger: '.marker',
        scrub: true,
        start:"top 50px",
      },
    });
    // pstl.to('.nav-wrap', { backgroundColor: 'rgba(255, 255, 255, 0.95)', duration: 0.5 })

    $('.smooth, .smooth a').click(function() {
      if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
        $('.top-bar').removeClass('open');
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({scrollTop: target.offset().top - 90}, 1000); // Set scroll speed here
          return false;
        }
      }
    });

    $('.no-link a').on('click', function(event) {
      event.preventDefault();
    });

    // Nav/Hamburger

    let mobBreak = window.matchMedia("(max-width: 1024px)").matches;
    let dtBreak = window.matchMedia("(min-width: 1025px)").matches;
    let screenSize;

    window.addEventListener('resize', event => {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        screenSize = true;
      } else {
        screenSize = false;
        $('.underlay').removeClass('show');
      }
    })


    $('.hamburger').on('click', function() {
      $(this).toggleClass('is-active');
      $('.top-bar').toggleClass('open');
      $('html').toggleClass('overflow');
      $('.underlay').toggleClass('show');
      $('body').toggleClass('rel');
      setTimeout(function() {
        $('.mega-menu').css('height', $(window).height() - 68);
      });

      if ($(this).hasClass('is-active')) {
        $('.mega-menu').removeClass('show');
        $('ul.two-col').removeClass('active');
      }

      if (mobBreak || screenSize) {
        $('.callout').css('display', 'none');
      }
    });

    $('.has-children a').on('click', function(event) {
      $(this).parent().toggleClass('active');
      $(this).parent().siblings().removeClass('active');
      $(this).parent().children('.mega-menu').toggleClass('show');
      $(this).parent().siblings().children('.mega-menu').removeClass('show');

      if ($('.mega-menu').hasClass('show') && $('.marker').scrollTop() <= 50) {
        $('.nav-wrap').addClass('nav-bg');
      } else {
        $('.nav-wrap').removeClass('nav-bg');
      }
      // event.preventDefault();
    });

    $('.has-children .tabs .tabs-title:first-child').addClass('is-active');
    $('.has-children .tabs-content .tabs-panel:first-child').addClass('is-active');

    if (mobBreak) {
      $('.tp-two-col h3').on('click', function() {
        $(this).next().addClass('active')
      });
    }

    $('.mobile-indicator-nav a').on('click', function(event) {
      $('.mega-menu').removeClass('show');
      $('.has-children').removeClass('active');
      event.preventDefault();
    });

    $('.back-to-main-menu-alt').on('click', function(event) {
      $('ul.two-col').removeClass('active');
      event.preventDefault();
    });

    $('.wrap.container').on('click', function() {
      $('.mega-menu').removeClass('show');
      $('.has-children').removeClass('active');
    });


    // END Nav/Hamburger
    const delays = [500, 1000, 1500];
    const delaysDt = [100, 500, 100];

    if (mobBreak || screenSize) {
      gsap.utils.toArray('.step').forEach((step, index) => {
        ScrollTrigger.create({
          trigger: step,
          start: 'top 80%',
          scrub: true,
          onEnter: () => {
            setTimeout(() => {
              step.classList.add('active');
            }, delays[index]);
          },
        });
      });
    } else {
      gsap.utils.toArray('.step').forEach((step, index) => {
        ScrollTrigger.create({
          trigger: step,
          start: 'top 80%',
          scrub: true,
          onEnter: () => {
            setTimeout(() => {
              step.classList.add('active');
            }, delaysDt[index]);
          },
        });
      });
    }
    

  // Split Component Animation
  function updateIsotopeLayout() {
    if ($(window).width() <= 1280) {
      $grid.isotope({
        masonry: {
          columnWidth: '.image-container',
          layoutMode: 'masonry',
        },
      });
    } else {
      $grid.isotope({
        masonry: {
          columnWidth: '.image-container',
          layoutMode: 'fitRows',
        },
      });
    }
  }
  
  if (
    $('body').hasClass('page-leaflink-for-retailers-data') ||
    $('body').hasClass('page-our-technology-data') || $('body').hasClass('page-brand-marketplace-data') || $('body').hasClass('page-marketplace-for-sellers-data')
    ) {
    var $grid = $('.image-items').isotope({
      itemSelector: '.image-container',
      masonry: {
        columnWidth: '.image-container',
        gutter: 20,
      },
    });
  
  ScrollTrigger.create({
    trigger: ".image-items",
    start: "top 100%",
    end: "bottom -100%",
    markers: false,
    onEnter: () => {
      const tl = gsap.timeline();

      tl.from(".image-items", {
        opacity: 0,
        y: 50,
        duration: 0.3,
        stagger: 0.1,
      });

      tl.from(".image-items .image-container", {
        opacity: 0,
        x: 50,
        y: 50,
        scale: 0.5,
        //rotation: -15,
        stagger: 0.2,
        duration: 0.3,
        ease: "power2.inOut",
      },
      "position(start)"
      );
    },
  });
  
  $(window).on('load resize', function () {
    updateIsotopeLayout();
  });
}

// Text Image Cluster Animation
if (
 $('body').hasClass('page-logistics-data')
 ) {
  ScrollTrigger.create({
    trigger: ".image-items",
    start: "top 100%",
    end: "bottom -100%",
    markers: false,
    onEnter: () => {
      const tl = gsap.timeline();

      tl.from(".image-items", {
        opacity: 0,
        y: 50,
        duration: 0.3,
        stagger: 0.1,
      });

      tl.from(".image-items img", {
        opacity: 0,
        x: 50,
        y: 50,
        scale: 0.5,
        //rotation: -15,
        stagger: 0.2,
        duration: 0.3,
        ease: "power2.inOut",
      },
      "position(start)"
      );
    },
  });
}

    //Blog Animation
    // if ($('body').hasClass('front-page-data') || $('body').hasClass('page-resources-data')) {
    //   $(window).scroll(function() {

    //     var wScroll = $(this).scrollTop();

    //     if(wScroll > $('#feed-card .feed-card').offset().top -($(window).height() / 1.2)) {

    //       $('#feed-card .feed-card').each(function(i){
    //         setTimeout(function(){
    //           $('#feed-card .feed-card').eq(i).addClass('fadeIn');
    //         }, 300 * (i+1));
    //       });
    //     } else {
    //       $('#feed-card .feed-card').removeClass('fadeIn');
    //     }

    //   });
    // }

    // if ($('body').hasClass('page-careers-data')) {
    //   var parallaxTop = $(".parallax-top");
    //   var sectionOffsetTop = $(".award-component").offset().top;

    //   $(window).on("scroll", function () {
    //     var scrollTop = $(window).scrollTop();

    //     if (scrollTop >= sectionOffsetTop) {
    //       var newTop = 228.46 + (scrollTop - sectionOffsetTop) * 0.5;
    //       parallaxTop.css("top", newTop + "px");
    //     } else {
    //       parallaxTop.css("top", "228.46px");
    //     }
    //   });
    // }

    // Blog Animation
    if ($('.blog-container').length > 0) {
      // Automatic fade-in if already above the fold on page load
      var isAboveTheFold = $('.blog-container').offset().top < $(window).height();
      if (isAboveTheFold) {
        $('.feed-card-container .feed-card').each(function(i){
          setTimeout(function(){
            $('.feed-card-container .feed-card').eq(i).addClass('fadeIn');
          }, 100 * (i+1));
        });
      }

      $(window).scroll(function() {

        var wScroll = $(this).scrollTop();

        if(wScroll > $('.feed-card-container .feed-card').offset().top -($(window).height() / 1.2)) {

          $('.feed-card-container .feed-card').each(function(i){
            setTimeout(function(){
              $('.feed-card-container .feed-card').eq(i).addClass('fadeIn');
            }, 300 * (i+1));
          });
        } else {
          $('.feed-card-container .feed-card').removeClass('fadeIn');
        }

      });
    }

    // Slick Slider

    $(document).on('click', '.mobile-filter', function(e){
      e.preventDefault();
      $(this).toggleClass('open');
      if ($(this).hasClass('open')) {
        $('.mobile-filter + .side-filter').slideDown();
      } else {
        $('.mobile-filter + .side-filter').slideUp('fast');
      }
    });

    $('.pf-filter-list').slick({
      dots: true,
      arrows: true,
      slidesPerRow: 2,
      rows: 3,
      infinite: false,
      fade: true,
      prevArrow: $('.prev-blog'),
      nextArrow: $('.next-blog'),
      responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesPerRow: 1,
          rows: 1,
        },
      },
      ],
    });

    function assessFilters(pt) {
      var cat = false,
      tags = false;

      cat = $('.top-filter a.active').attr('data-filter');

      if ($('.side-filter a.active').length > 0) {
        tags = '';
      }

      var t = 0; $('.side-filter a.active').each(function(){
        t++; if (t > 1) { tags += ' '; }
        tags += $(this).attr('data-filter');
      });

      $('.pf-filter-list').slick('unslick');
      $('.pf-filter-list').html('');

      $.ajax({
        url: ajaxurl+'?action=filterList',
        type: 'post',
        data: {posttype: pt, cat: cat, tags: tags},
        success: function(data) {
          var dataArray = JSON.parse(data),
          html = dataArray['html'];

          $('.pf-filter-list').html(dataArray['html'])
          $('.pf-filter-list').slick({
            dots: true,
            arrows: true,
            slidesPerRow: 2,
            fade: true,
            rows: 3,
            infinite: false,
            prevArrow: $('.prev-blog'),
            nextArrow: $('.next-blog'),
            responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesPerRow: 1,
                rows: 1,
              },
            },
            ],
          });
        },
        error: function(jqXHR, textStatus, errorThrown){
          console.log(jqXHR);
          console.log(textStatus);
          console.log(errorThrown);
        },
      });
    }

    $(document).on('click', '.top-filter a', function(e){
      e.preventDefault();
      if (!$(this).hasClass('.active')) {
        var pt = $(this).attr('data-pt'),
        ac = $(this).attr('data-active');
        $('.top-filter').attr('data-active', ac);
        $('.top-filter a.active').removeClass('active');
        $(this).addClass('active');
        assessFilters(pt);
      }
    });

    $(document).on('click', '.side-filter a', function(e){
      e.preventDefault();
      if (!$(this).hasClass('.active')) {
        var pt = $(this).attr('data-pt'),
        ac = $(this).attr('data-active');
        $('.side-filter').attr('data-active', ac);
        $('.side-filter a.active').removeClass('active');
        $(this).addClass('active');
        assessFilters(pt);
      }
    });

    // Slick Slider
    $('.vcs').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      centerMode: true,
      prevArrow: $('.fcs-left'),
      nextArrow: $('.fcs-right'),
      responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
        },
      },
      ],
    });

    $('.values-slider').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      useTransform: true,
      cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
      prevArrow: $('.vs-left'),
      nextArrow: $('.vs-right'),
    });

    $('.logo-slider-container').slick({
      dots: false,
      arrows: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 8000,
      cssEase: 'linear',
      responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      ],
    });

    $('.zoom-slider').slick({
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: true,
      draggable: false,
      accessibility: false,
      infinite: true,
      responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      ],
    });

    $(document).on('click', '.zoom-right', function(e){
      e.preventDefault();
      $('.zoom-slider .slick-current').addClass('pretransition');
      setTimeout(function(){
        $('.zoom-slider').slick('slickNext');
        $('.zoom-slider .pretransition').removeClass('pretransition');
      }, 350);
    });

    $(document).on('click', '.zoom-left', function(e){
      e.preventDefault();
      $('.zoom-slider .slick-current').addClass('pretransition');
      setTimeout(function(){
        $('.zoom-slider').slick('slickPrev');
        $('.zoom-slider .pretransition').removeClass('pretransition');
      }, 400);
    });

    $('.if_filter_list').slick({
      dots: true,
      arrows: true,
      slidesPerRow: 2,
      fade: true,
      rows: 3,
      infinite: false,
      prevArrow: $('.prev-if'),
      nextArrow: $('.next-if'),
      responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesPerRow: 1,
        },
      },
      ],
    });

    function generateSlickPages(pagination, event, slick, currentSlide, nextSlide) {
      if(!slick.$dots){
        $(pagination).addClass('hidden-nav');
        return;
      }

      $(pagination+'.hidden-nav').removeClass('hidden-nav');

      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      // use dots to get some count information
      $(pagination+' .curr-page').text(i);
      $(pagination+' .max-page').text(slick.$dots[0].children.length);
    }

    // Callout

    $('.pinned-alt .frame-wrap').slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // useTransform: true,
      // cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
      vertical: true,
      verticalScrolling: true,
      prevArrow: $('.vs-left'),
      nextArrow: $('.vs-right'),
    });


    // let currentIndex = 0
    // const altpanels = Array.from(document.querySelectorAll('.alt-frame'))
    // if (altpanels.length) {
    //   ScrollTrigger.create({
    //     trigger: '.pinned-alt',
    //     pin: true,
    //     pinSpacing: true,
    //     scrub: true,
    //     start: 'top 200px',
    //     end: function () {
    //       return '+=' + window.innerHeight
    //     },
    //     toggleClass: 'is-active',
    //     onEnter: function () {
    //       altpanels[0].classList.add('is-active')
    //     },
    //     onLeaveBack: function () {
    //       altpanels[0].classList.remove('is-active')
    //     },
    //     onUpdate: function (self) {
    //       const previousIndex = currentIndex

    //       currentIndex = Math.floor(self.progress * altpanels.length)

    //       if (previousIndex >= altpanels.length || currentIndex >= altpanels.length)
    //         return

    //       if (previousIndex !== currentIndex) {
    //         altpanels[previousIndex].classList.remove('is-active')
    //         altpanels[currentIndex].classList.add('is-active')
    //       }
    //     },
    //   })
    // }

    // Callout
    // let currentIndexB = 0
    // const altpanelsB = Array.from(document.querySelectorAll('.alt-frame'))
    // if (altpanelsB.length) {
    //   ScrollTrigger.create({
    //     trigger: '.pinned-alt-cta',
    //     pin: true,
    //     pinSpacing: true,
    //     scrub: true,
    //     start: 'top 250px',
    //     end: function () {
    //       return '+=' + window.innerHeight
    //     },
    //     toggleClass: 'is-active',
    //     onEnter: function () {
    //       altpanelsB[0].classList.add('is-active')
    //     },
    //     onLeaveBack: function () {
    //       altpanelsB[0].classList.remove('is-active')
    //     },
    //     onUpdate: function (self) {
    //       const previousIndexB = currentIndexB

    //       currentIndexB = Math.floor(self.progress * altpanelsB.length)

    //       if (previousIndexB >= altpanelsB.length || currentIndexB >= altpanelsB.length)
    //         return

    //       if (previousIndexB !== currentIndexB) {
    //         altpanelsB[previousIndexB].classList.remove('is-active')
    //         altpanelsB[currentIndexB].classList.add('is-active')
    //       }
    //     },
    //   })
    // }

    // Fill Map Component
    const body = $('body');
    const isAboutUsPage = body.hasClass('page-about-us-data');
    const isBrandsPage = body.hasClass('page-leaflink-for-brands-data');
    let fillColor = isAboutUsPage ? '#ffffff' : (isBrandsPage ? 'transparent' : '');

    if (isAboutUsPage || isBrandsPage) {
      const statePaths = document.querySelectorAll(".state-path");

      statePaths.forEach((path) => {
        gsap.to(path, {
          scrollTrigger: {
            trigger: path,
            start: "top 70%",
            end: "bottom 10%",
            toggleClass: { targets: path, className: "fill-active" },
            markers: false,
            onEnter: () => {
              const dataFillColor = path.getAttribute("data-fill-color");
              path.style.fill = dataFillColor || fillColor;
            },
            onLeaveBack: () => {
              path.style.fill = fillColor;
            },
          },
        });
      });
    }

    // Counter Container
    var isAlreadyRun = false;

    if($('body').hasClass('single-llcase_studies-data') || $('body').hasClass('page-customers-brands-data') || $('body').hasClass('page-customers-retailers-data')) {
      $(window).scroll(function () {
        $('.counter-container').each(function (i) {
          var bottom_of_object = $(this).position().top + $(this).outerHeight() / 2;
          var bottom_of_window = $(window).scrollTop() + $(window).height();

          if (bottom_of_window > (bottom_of_object + 20)) {
            if (!isAlreadyRun) {
              $('.count').each(function () {
                $(this).prop('Counter', 0).animate({
                  Counter: $(this).text(),
                },
                {
                  duration: 3500,
                  easing: 'swing',
                  step: function (now) {
                    $(this).text(Math.ceil(now));
                  },
                });
              });
            }
            isAlreadyRun = true;
          }
        });
      });
    }

    //Animated Elements
    function animateOnScroll(element, animation) {
      const animationSettings = Object.assign({
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          end: 'bottom 20%',
          toggleActions: 'play none none none',
          markers: false,
        },
      }, animation);
    
      gsap.from(element, animationSettings);
    }

  function setupScrollTriggerForSection(sectionSelector, animationConfig) {
    const sections = document.querySelectorAll(sectionSelector);

    sections.forEach((section) => {
      const elements = gsap.utils.toArray(section.querySelectorAll('.animated-element'));

      if (elements.length > 0) {
        elements.forEach((element) => {
          animateOnScroll(element, animationConfig);
        });
      }
    });
  }

  const animationConfig = {
    opacity: 1,
    scale: 1.5,
    duration: 1.5,
    ease: 'power1.out',
  };

  setupScrollTriggerForSection('section', animationConfig);

  // Floating Link Cards
  if ($('body').hasClass('front-page-data')) {
    $('#cs-tabs').on('click', 'li[data-filter="#panel-2"]', function() {
      $('.floating-link-cards').addClass('change-background');
    });

    $('#cs-tabs').on('click', 'li[data-filter="#panel-1"]', function() {
      $('.floating-link-cards').removeClass('change-background');
    });
  }

  function initMasonry() {
    var container = document.querySelector(".floating-card-container");

    var masonry = new Masonry(container, {
      itemSelector: ".floating-link-card",
      columnWidth: ".floating-link-card",
    });

    var card1 = document.querySelector(".card-1");
    var card2 = document.querySelector(".card-2");
    var card3 = document.querySelector(".card-3");
    var card4 = document.querySelector(".card-4");
    var card5 = document.querySelector(".card-5");

    if (card1 && card2) {
      card1.style.top = "0";
      var card2Top = parseFloat(getComputedStyle(card1).top || "0") + parseFloat(getComputedStyle(card1).height || "0");
      card2.style.top = card2Top + "px";
    }

    if (card3 && card4 && card5) {
      var card3Top = parseFloat(getComputedStyle(card3).top || "0");
      card3.style.left = "0px";
      var card3Width = parseFloat(getComputedStyle(card3).width || "0");
      var card4Left = card3Width;
      card4.style.left = card4Left + "px";
      var card4Width = parseFloat(getComputedStyle(card4).width || "0");
      var card5Left = card4Left + card4Width;
      card5.style.left = card5Left + "px";
      card4.style.top = card3Top + "px";
      card5.style.top = card3Top + "px";
    }
  }

  function checkScreenWidth() {
    var screenWidth = window.innerWidth;

    if (screenWidth >= 1280) {
      setTimeout(initMasonry, 1000);
    }
  }

  checkScreenWidth();

  window.addEventListener("resize", checkScreenWidth);


  // Marquee Cluster

  let mqcl = gsap.timeline({
    scrollTrigger: {
      trigger: '.marquee.cluster',
      start: 'top 130px',
    },
  });
  mqcl.from('.box', { y: 50, opacity: 0, duration: 0.5, stagger: 0.1 })

// Map Component Pricing 
function highlightState(stateId) {
  const paths = document.querySelectorAll('.state-path');
  paths.forEach((path) => {
    path.classList.remove('highlighted');
  });

  const selectedPath = document.getElementById(stateId);
  if (selectedPath) {
    selectedPath.classList.add('highlighted');
  }
}

if ($('body').hasClass('page-pricing-a-data')) {
  const customDropdown = document.querySelector('.custom-dropdown');
  const customDropdownLabel = document.querySelector('.custom-dropdown-label');
  const customOptions = document.querySelector('.custom-options');
  const customOptionItems = document.querySelectorAll('.custom-option');

  let selectedStateId = '';

  customDropdownLabel.addEventListener('click', function () {
    customOptions.style.display = customOptions.style.display === 'block' ? 'none' : 'block';
  });

  customOptionItems.forEach(function (item) {
    item.addEventListener('click', function () {
      const selectedValue = this.getAttribute('data-value');
      customDropdownLabel.textContent = this.textContent;
      customOptions.style.display = 'none';

      selectedStateId = selectedValue;
    });
  });

  document.getElementById('go-button').addEventListener('click', function () {
    highlightState(selectedStateId);
  });
}

$(document).on('click', '.case-study-tab-system .tab-container .tabs li', function(e){
  e.preventDefault();
  if (!$(this).hasClass('.is-active')) {
    var ac = $(this).attr('data-active');
    $('.case-study-tab-system .tab-container .tabs').attr('data-active', ac);
    $('.case-study-tab-system .tab-container .tabs li.is-active').removeClass('is-active');
    $(this).addClass('is-active');
  }
});

$('.split-accordion-flush-image-left .accordion .accordion-item:first-child').addClass('is-active');
$('.split-accordion-flush-image-left .accordion .accordion-item:first-child .accordion-content').css('display', 'block');

if (dtBreak) {
  ScrollTrigger.create({
    trigger: '.icon-list-zoom .image-container',
    start: 'top 20%',
    pin: true,
    endTrigger: '.icon-list-zoom .buckets-container',
    end: 'bottom center',
  });
}
},
finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
